<template>
    <div >
        <div class="content-main" style="margin-top: 0px;">
            <div class="filter-wrap">
                <expand-filter
                    :formData="formData"
                    @clickBtn="clickBtn"
                    @changeBtnFormType="changeBtnFormType"
                    @changeSel="changeSel"
                    :closeWidth="944"
                    marginBottom="0px"
                    borderRadius="0px 4px 4px 4px"
                >
                    <template #datePicker="{slotData}">
                        <div class="date-picker-wrap">
                            <el-date-picker
                                v-model="slotData.value"
                                style="width:330px;"
                                :clearable="false"
                                type="datetimerange"
                                start-placeholder="请选择开始时间"
                                end-placeholder="请选择结束时间"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                :default-time="['00:00:00', '23:59:59']"
                            >
                            </el-date-picker>
                            <el-tooltip
                                placement="top"
                                effect="light"
                            >
                                <div slot="content">
                                    在校历中配置学期后<br />会默认筛选本学期的数据
                                </div>
                                <div class="date-icon">i</div>
                            </el-tooltip>
                        </div>
                    </template>
                    <div slot="other-btn" style="margin-left:10px;padding-bottom: 10px; display:flex;flex:1;">
                        <el-button
                            v-has-permi="['moralmanage:moraldocument:assessment']"
                            type="primary"
                            @click="clickSmartReviews"
                        >
                            智能考核
                        </el-button>
                    <!-- <el-button
                        v-has-permi="['moralmanage:moraldocument:room:rule']"
                        type="primary"
                        @click="clickTargetManage"
                    >
                        指标管理
                    </el-button> -->
                    <el-button
                        v-has-permi="['moralmanage:moraldocument:notice']"
                        type="primary"
                        @click="clickNoticeManage"
                    >
                        通知管理
                    </el-button>
                </div>
                <div slot="dropdown"
                    style="margin-left: 10px;
                    flex: 1;
                    display: flex; justify-content: flex-end;">
                    <el-dropdown
                        trigger="click"
                         style="background-color: #ffffff;
                            margin-left:10px;"
                        >
                        <el-button>
                            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div style="display: flex;justify-content:center">
                                    <el-button
                                    type="text"
                                            class="hover"
                                            plain
                                        v-has-permi="['moralmanage:moraldocument:document']"
                                        @click="clickArchivesManage"
                                    >
                                        归档管理
                                    </el-button>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div style="display: flex;justify-content:center">
                                    <el-button
                                        type="text"
                                            class="hover"
                                            plain
                                        v-has-permi="['moralmanage:moraldocument:import']"
                                        @click="clickImport"
                                    >
                                        导入
                                    </el-button>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div style="display: flex;justify-content:center">
                                    <el-button
                                    type="text"
                                            class="hover"
                                            plain
                                        :loading="exportLoading"
                                        v-has-permi="['moralmanage:moraldocument:export']"
                                        @click="clickExport"
                                    >
                                        导出
                                    </el-button>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                </expand-filter>

            </div>
            <table-data
                v-loading="loadingTable"
                :config="table_config"
                :tableData="tableData"
                @handleSortChange="handleSortChange"
                ref="table"
                :to-top="false"
            >
                <template #studentName="{data}">
                    <el-button
                        type="text"
                        @click="clickStudentName(data)"
                        :disabled="
                            !hasPermission(['moralmanage:moraldocument:detail'])
                        "
                    >
                        {{ data.studentName }}
                    </el-button>
                </template>
                <template #operation="{data}">
                    <xk-button
                        v-has-permi="['moralmanage:moraldocument:add']"
                        text
                        type="primary"
                        @click="clickAddAudit(data)"
                    >
                        添加考核
                    </xk-button>
                </template>
            </table-data>
            <pagination
                @pagination="changeGetList()"
                :page.sync="listQuery.pageNum"
                :limit.sync="listQuery.pageRow"
                :total="total"
            ></pagination>
            <!-- 添加考核 -->
            <dialog-wrapper
                :dialog-obj="dialogObj"
                @handleClose="handleClose"
                class="simple-dialog-form"
            >
                <div class="common-form">
                    <el-form
                    ref="formRef"
                    :model="ruleForm"
                    label-width="120px"
                    label-position="right"
                    :rules="rules"
                >
                    <el-form-item
                        label="考核日期"
                        prop="assessDate"
                    >
                        <el-date-picker
                            v-model="ruleForm.assessDate"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            clearable
                            placeholder="请选择考核日期"
                            class="input-width-medium"
							@change="handleDateChange"
                        />
                    </el-form-item>
                    <el-form-item
                        label="考核类型"
                        prop="ruleType"
                    >
                        <el-radio
                            v-model="ruleForm.ruleType"
                            label="1"
                            @change="changeRuleType"
                        >
                            批评
                        </el-radio>
                        <el-radio
                            v-model="ruleForm.ruleType"
                            label="2"
                            @change="changeRuleType"
                        >
                            表扬
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        label="考核指标"
                        prop="ruleId"
                    >
                        <el-select-tree
                            v-model="ruleForm.ruleId"
                            :data="
                                treeFilter(
                                    treeOptions,
                                    'ruleType',
                                    ruleForm.ruleType,
                                )
                            "
                            :props="{
                                value: 'id',
                                label: 'ruleName',
                                children: 'children',
                            }"
                            clearable
                            filterable
                            @change="changeRule"
                            class="input-width-medium"
                        >
                        </el-select-tree>
                    </el-form-item>
                    <el-form-item
                        label="考核行为"
                        prop="behaviorIds"
                    >
                        <el-select
                            v-model="ruleForm.behaviorIds"
                            :popper-append-to-body="false"
                            clearable
                            filterable
                            multiple
                            :title="' '"
                            @change="changeBehavior"
                            class="input-width-medium behavior-select"
                        >
                            <el-option
                                v-for="(item, index) in behaviorList"
                                :label="item.ruleName"
                                :value="item.id"
                                :key="index"
                                :title="item.ruleName"
                                :disabled="behaviorDisabled(item)"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="上传凭证"
                        prop="img"
                    >
                    <div style="display: flex;">
                        <el-upload
                            :action="action"
                            :headers="headers"
                            :multiple="false"
                            :file-list="fileList1"
                            :on-success="handleSuccess"
                            :before-upload="beforeUpload"
                            :accept="acceptValue"
                            :show-file-list="false"
                            :limit="3"
                            :on-exceed="handleExceed"
                            class="avatar-uploader"
                            :data="{
                                schoolId: schoolId,
                                mode: 'moral',
                            }"
                        >
                            <div v-if="isUpdataImg && isUpdataVideo" class="upload-wrap">
                                <div class="upload-btn">
                                    <div class="add-icon">+</div>
                                    <div class="upload-text">上传</div>
                                </div>
                            </div>
                        </el-upload>
                        <div v-if="ruleForm.img.length > 0" class="swiper-wrap">
                            <div
                                v-for="(item, index) in ruleForm.img"
                                :key="index"
                                class="img-wrap"

                            >
                            <div class="img_each">
                                <video v-if="item.indexOf('mp4') > -1" style="width: 80px;height: 80px;border: 1px solid #c0ccda;" :src="item" ></video>
                                <img v-else style="width: 80px;height: 80px;border: 1px solid #c0ccda;" :src="item" alt="">
                                <div class="del">
                                    <i class="el-icon-delete" @click.stop="deleteSwiperImg(index)"></i>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <span class="tipText">支持上传视频和照片，视频要求短于20s，小于100M。</span>
                    </el-form-item>
                    <el-form-item
                        :label="
                            ruleForm.ruleType === '1' ? '扣分分值' : '加分分值'
                        "
                        prop="score"
                    >
                        <el-input
                            class="input-width-medium"
                            v-model="totalScore"
                            readonly
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="考核说明"
                        prop="remark"
                    >
                        <el-input
                            v-model="ruleForm.remark"
                            clearable
                            rows="5"
                            type="textarea"
                            maxlength="100"
                            show-word-limit
                            placeholder="请输入考核说明"
                            class="input-width-medium"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div class="footer-button-group">
                    <xk-button @click="handleClose">取消</xk-button>
                    <xk-button
                        :loading="btnLoading"
                        type="primary"
                        @click="handleSave"
						:disabled="disableSubmit"
                    >
                        确定
                    </xk-button>
                </div>
                </div>
            </dialog-wrapper>
            <!-- 归档管理 -->
            <dialog-wrapper
                :dialog-obj="archivesDialogObj"
                @handleClose="handleArchivesClose"
                class="simple-dialog-form"
            >
                <div class="archives-wrap common-form">
                    <p class="text1">可按学期将德育档案归档管理：</p>
                    <el-form
                        ref="archivesRef"
                        :model="archivesForm"
                        label-width="92px"
                        :rules="archivesRules"
                    >
                        <el-form-item
                            label="选择学期"
                            prop="calendarId"
                        >
                            <el-select
                                v-model="archivesForm.calendarId"
                                clearable
                                filterable
                                @change="changeSemester"
                                :no-data-text="noDataText"
                                class="input-width-medium"
                            >
                                <el-option
                                    v-for="(item, index) in semesterList"
                                    :value="item.id"
                                    :label="item.name"
                                    :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div
                        v-loading="activeLoading"
                        class="archives-list-wrap"
                    >
                        <p class="text2">已归档的德育档案：</p>
                        <template v-if="archivesList.length > 0">
                            <div
                                v-for="(item, index) in archivesList"
                                :key="index"
                                class="archives-item"
                            >
                                <div class="archives-name">
                                    <i class="el-icon-circle-check"></i>
                                    <span>{{ item.name }}德育档案</span>
                                </div>
                                <el-button
                                    type="text"
                                    class="primary"
                                    @click="activeArchive(item, index)"
                                    >激活</el-button
                                >
                            </div>
                        </template>
                        <p
                            v-else
                            class="empty-text"
                        >
                            暂无数据
                        </p>
                    </div>
                </div>
                <div class="footer-button-group">
                    <el-button @click="handleArchivesClose">取消</el-button>
                    <el-button
                        :loading="btnLoading"
                        type="primary"
                        @click="handleArchivesSave"
                    >
                        归档
                    </el-button>
                </div>
            </dialog-wrapper>
            <!-- 导入弹窗 -->
            <dialog-wrapper
                :dialogObj="importDialog"
                @handleClose="importDialogClose"
            >
                <import-dialog
                    :loading="btnLoading"
                    :file-list="fileList"
                    :headers="headers"
                    @handleChange="handleChange"
                    @downloadTemplate="downloadTemplate"
                    @importDialogClose="importDialogClose"
                    @importDialogSubmit="importDialogSubmit"
                ></import-dialog>
            </dialog-wrapper>
        </div>
    </div>
</template>

<script>
import { Pagination, DialogWrapper,debounce} from "common-local";
import TableData from "../Sub/TableData"
import ExpandFilter from "../Sub/ExpandFilter.vue";
import ImportDialog from "./AssessmentList/ImportDialog.vue";
import ElSelectTree from "el-select-tree";
import {mapState} from "vuex";
import {downloadFile, formatTreeData, treeFind, listToTree, hasPermission} from "@/libs/utils";
import {getToken} from "@/libs/auth";
import {CheckTeacherModel} from "@/models/CheckTeacher";
import onResize from "@/mixins/onResize"
import RecordsList from '@/libs/pageListOptimal.js';
export default {
    name: "AssessmentList",
    mixins: [onResize],
    components: {
        TableData,
        ExpandFilter,
        Pagination,
        DialogWrapper,
        ElSelectTree,
        ImportDialog,
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
        noDataText() {
            if (this.schoolCalendar.length === 0) {
                return "未配置校历";
            } else if (this.semesterList.length === 0) {
                return "没有可归档校历";
            }
        },
        // 所有批评考核行为
        criticismBehaviorListAll() {
            if (this.behaviorListAll && this.behaviorListAll.length > 0) {
                let resMap = this.behaviorListAll.filter(item => item.ruleType == '1');
                return resMap;
            } else {
                return []
            }
        },
        // 所有表扬考核行为
        praiseBehaviorListAll() {
            if (this.behaviorListAll && this.behaviorListAll.length > 0) {
                let resMap = this.behaviorListAll.filter(item => item.ruleType == '2');
                return resMap;
            } else {
                return []
            }
        },
    },
    data() {
        return {
            hasPermission,
            btnLoading: false,
            loadingTable: true,
            activeLoading: false,
            exportLoading: false,
            acceptValue:"image/*,video/mp4", //允许上传的类型
            acceptType:"", //记录上传的类型
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: "",
                stuName: "",
                gradeId: "",
                classId: "",
                assessDateStart: "",
                assessDateEnd: "",
                sortType:'',  // 批评
                sort:'',   // 表扬
                organIds: [],
                masterName: "",
                studyNo: "",
                onlyCode: "",
            },
            formData: {
                data: [
                    {
                        type: "input",
                        key: "stuName",
                        value: "",
                        placeholder: "请输入学生姓名",
                    },
                    {
                        label: "",
                        placeholder: "班级",
                        type: "cascader",
                        key: "organIds",
                        value: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: true,
                            value: "id",
                            checkStrictly: false,
                            emitPath: false
                        },
                        filter(data) {
                            return data.organType != 1;
                        },
                    },
                    // {
                    //     type: "select",
                    //     key: "gradeId",
                    //     value: "",
                    //     list: [],
                    //     placeholder: "请选择年级",
                    // },
                    // {
                    //     type: "select",
                    //     key: "classId",
                    //     value: "",
                    //     list: [],
                    //     placeholder: "请选择班级",
                    // },
                    {
                        type: "input",
                        key: "masterName",
                        value: "",
                        placeholder: "请输入班主任姓名",
                    },
                    {
                        type: "input",
                        key: "studyNo",
                        value: "",
                        placeholder: "请输入学号",
                    },
                    {
                        type: "input",
                        key: "onlyCode",
                        value: "",
                        placeholder: "请输入唯一号",
                    },
                    {
                        type: "slot",
                        slotName: "datePicker",
                        key: "dateArr",
                        value: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["moralmanage:moraldocument:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["moralmanage:moraldocument:list"],
                    },
                ],
                btnFormType: true,
            },
            table_config: {
                thead: [
                    // {
                    //     label: "",
                    //     labelWidth: "27px",
                    // },
                    {
                        type: "slot",
                        slotName: "studentName",
                        fixed: false,
                        label: "学生姓名",
                        prop: "studentName",
                        align: "center",
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "班主任",
                        prop: "masterName",
                        align: "center",
                        type: "tooltipColumn",
                        labelWidth: "160px",
                        splitSign: ","
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: "center",
                    },
                    {
                        label: "批评记录",
                        prop: "breakRuleNum",
                        align:'center',
                        sortable: "custom",
                    },
                    {
                        label: "表扬记录",
                        prop: "honorNum",
                        align:'center',
                        sortable: "custom",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        labelWidth: "150",
                        className: 'text-spacing-reduction'
                        // align: "left",
                    },
                ],
                height: "",
                check: false,
            },
            total: 0,
            tableData: [],
            isUpdataImg:true,
            isUpdataVideo:true,
            dialogObj: {
                title: "添加考核",
                width: "668px",
                dialogVisible: false,
            },
            ruleForm: {
                schoolId: "",
                classId: "",
                gradeId: "",
                onlyCode: "",
                studentId: "",
                assessDate: "",
                ruleId: "",
                behaviorIds: [],
                img: [],
                score: "",
                remark: "",
                ruleType: "1",
            },
            totalScore:0,
            rules: {
                assessDate: [

                ],
                ruleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核指标",
                    },
                ],
                behaviorIds: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核行为",
                    },
                ],
                score: [
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "分值最多支持一位小数点",
                    },
                ],
            },
            ruleList: [],
            treeOptions: [],//指标列表
            behaviorList: [],
            behaviorListAll: [],//所有行为列表

            archivesDialogObj: {
                title: "归档管理",
                width: "640px",
                dialogVisible: false,
            },
            archivesForm: {
                schoolId: "",
                name: "",
                calendarId: "",
                startTime: "",
                endTime: "",
            },
            archivesRules: {
                calendarId: [
                    {required: true, trigger: "change", message: "请选择学期"},
                ],
            },
            semesterList: [],
            archivesList: [],
            importDialog: {
                title: "导入",
                dialogVisible: false,
                width: "500px",
            },
            fileList: [],
            fileList1: [],
            firstTime: true,
            gradeList: [],
            schoolCalendar: [],
            currentCalendar: [],
			disableSubmit:false,
            organList: [],
            treeData: {
                type: "selectTree",
                label: "班级",
                value: "",
                placeholder: "请选择班级",
                show: true,
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                dataList: [],
                showAlways: true,
                filter(data) {
                    return data.organType != 1;
                },
            },
        };
    },
    created() {
        this.firstTime = false;
        this.initBaseData("created");
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.initBaseData("activated");
        }
    },
    deactivated() {
        this.firstTime = true;
    },
    methods: {
        handleUploadChange(file, fileList){
            console.log(file.type, fileList,this.acceptValue,'000000000000000000222');
            // if (this.acceptValue === "video/mp4" && fileList.length > 1) {
            //     this.$message.error("只能上传一个视频");
            //     fileList.splice(0, fileList.length - 1);
            // }
        },
		/**
		 * @Description: 考核日期选择change事件
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 邵澳
		 * @Date: 2023/8/2 15:44
		 */
		handleDateChange(){

		},
		/**
		 * @Description: 自定义校验考核日期
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 邵澳
		 * @Date: 2023/8/2 15:44
		 */
		checkExamineDate:debounce(function (rule, value, callback){
			if (!value) {
				return callback(new Error('请选择考核日期'));
			}

			//调接口判断是否能添加考核
			this.judegIsExamine().then(res=>{
				if(res.data.code == '200'){
					if(res.data.data.status == '1'){
						this.disableSubmit = false;
						return callback();
					}else{
						this.disableSubmit = true;
						return callback(new Error('学生在该时间段已请假，无法进行考核'));
					}
				}
			})
		}),
		/**
		 * @Description: 判断该学校请假时是否允许考核，不允许的话再判断该学生在选中的考核时间内是否请假了，请假了的话不能考核，接口去判断
		 * @DoWhat:
		 * @UseScenarios:
		 * @Attention:
		 * @Author: 邵澳
		 * @Date: 2023/8/2 15:44
		 */
		judegIsExamine(){
			return new Promise((resolve)=>{
                const {
                    assessDate,
                    schoolId,
                    studentId
                } = this.ruleForm
				this._fet(
					"/school/schoolMoralStudentDetail/verify",
					{assessDate, schoolId, studentId},
				).then((res) => {
					resolve(res)
				});
			})
		},
        async initBaseData(key) {
            let recordsList = new RecordsList();
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
            let slotStatus = this.hasPermission(["moralmanage:moraldocument:add"])
            if ( slotStatus ) {
                console.log('111');
            }else{
                this.table_config.thead.splice(-1,1)
                console.debug(JSON.parse(JSON.stringify(this.table_config.thead)),"nnnnn");
            }
            this.listQuery.schoolId = this.schoolId;
            await this.getCurrentCalendar()
            await this.getGradeList();
            if (key === 'created') {
                this.setDefaultDate()
            }
            this.getList();
            this.getRuleList();
            this.getMoralCalendar();
            this.getMoralDocument();
            this.getSchoolCalendar();
            this.getOrg();
            this.getBehaviorListAll();
			this.rules.assessDate.push({ validator: this.checkExamineDate, trigger: 'change' });
        },
        /**
         * @Description: 获取年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 16:15
         */
        async getGradeList() {
            let params = {
                schoolId: this.schoolId,
                jobScope: ["2", "3"],
                needPermissionConfig: '1'
            };
            await this._fet(
                "/school/schoolOrgan/getGradeListByMoralManage",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.gradeList = res.data.data.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }));
                    this.setFormDataList("gradeId", this.gradeList);
                }
            });
        },
        // 获取班级数据
        getClassList(gradeId) {
            let params = {
                schoolId: this.schoolId,
                gradeId: gradeId,
                jobScope: ["2", "3"],
                needPermissionConfig: '1'
            };
            this._fet(
                "/school/schoolOrgan/getClassListByMoralManage",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    let classList = res.data.data.map((i) => ({
                        label: i.name,
                        value: i.id,
                    }));
                    this.setFormDataList("classId", classList);
                }
            });
        },
        setFormDataList(type, list) {
            const index = this.formData.data.findIndex(
                (item) => item.key === type,
            );
            if (index > -1) {
                this.formData.data[index].list = list;
            }
        },
        /**
         * @Description: 获取学期下拉数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/31 18:21
         */
        getMoralCalendar() {
            this._fet("/school/schoolCalendar/getMoralCalendar", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    res.data.data.forEach((item) => {
                        item.startTime = this.formatDate(item.startTime);
                        item.endTime = this.formatDate(item.endTime);
                    });
                    this.semesterList = res.data.data;
                }
            });
        },
        getMoralDocument() {
            this._fet("/school/schoolMoralDocument/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.archivesList = res.data.data;
                }
            });
        },
        /**
         * @Description: 设置默认查询时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/31 18:57
         */
        getSchoolCalendar() {
            let params = {
                pageNum: 1,
                pageRow: 1,
                schoolId: this.schoolId,
            };
            this._fet("/school/schoolCalendar/list", params).then((res) => {
                if (res.data.code === "200") {
                    this.schoolCalendar = res.data.data.list
                }
            });
        },
        async getCurrentCalendar() {
            let params = {
                schoolId: this.schoolId,
                currentDate: this.$moment().format("YYYY-MM-DD HH:MM:SS")
            };
            await this._fet("/school/schoolCalendar/listByCondition", params).then((res) => {
                if (res.data.code === "200") {
                    this.currentCalendar = res.data.data
                }
            });
        },
        /**
         * @Description: 设置默认查询日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/12 13:08
         */
        setDefaultDate() {
            this.listQuery.assessDateStart = this.$moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD HH:mm')
            this.listQuery.assessDateEnd = this.$moment().format("YYYY-MM-DD HH:mm")
            if (this.currentCalendar.length > 0) {
                // 根据校历设置默认查询时间
                let {startTime, endTime} = this.currentCalendar[0];
                startTime = this.formatDate(startTime);
                endTime = this.formatDate(endTime);
                if (
                    this.listQuery.assessDateEnd < endTime &&
                    this.listQuery.assessDateEnd > startTime
                ) {
                    this.listQuery.assessDateStart = startTime.concat(' 00:00');
                }
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.listQuery.assessDateStart,
                        this.listQuery.assessDateEnd,
                    ];
                }
            });
        },
        changeGetList(){
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.getList()
        },
        // 获取表格数据
        getList(t) {
            if (t === 1) {
                this.listQuery.pageNum = 1;
            }
            this.loadingTable = true;
            this._fet("/school/schoolMoralStudentDetail/list", this.listQuery)
                .then((res) => {
                    if (res.data.code === "200") {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.totalCount;
                        //获取--存储筛选项及滚动条高度
                        this.$nextTick(() => {
                            let recordsList =  new RecordsList()
                            if(recordsList.get(this.$route.name)){
                                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                            }
                            console.log(recordsList.get(this.$route.name),'获取--存储筛选项及滚动条高度');

                            //移除--存储筛选项及滚动条高度
                            recordsList.remove(this.$route.name)
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
         /**
          * @Description: 排序
          * @DoWhat:
          * @UseScenarios:
          * @Attention:
          * @Author: 闫乔
          * @Date: 2023-08-31 10:07:07
          * @param {*} column
          * @param {*} order
          * @param {*} prop
          */
         handleSortChange({column, order, prop}){
            if(prop === "breakRuleNum"){
                this.listQuery.sortType = order === "ascending" ? 'asc'  : 'desc'
                this.listQuery.sort = '1'
            }
            if(prop === "honorNum"){
                this.listQuery.sortType = order === "ascending" ? 'asc'  : 'desc'
                this.listQuery.sort = '2'
            }
            this.getList(1);
        },
        getRuleList() {
            this._fet("/school/schoolMoralRule/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.ruleList = formatTreeData(
                        res.data.data,
                        "id",
                        "parentId",
                    );
                    this.treeOptions = this.ruleList
                    console.log('this.treeOptions',this.treeOptions);
                }else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
         treeFilter(data, findKey, findVal, subarrKey) {
            if (findVal) {
                let newData = data.filter((x) => x[findKey] === findVal);
                newData.forEach(
                    (x) =>
                        x[subarrKey] &&
                        (x[subarrKey] = this.treeFilter(
                            x[subarrKey],
                            findKey,
                            findVal,
                            subarrKey,
                        )),
                );
                return newData;
            } else {
                return data;
            }
        },
        // 查询/重置
        clickBtn(ev) {
            switch (ev.item.fn) {
                case "primary":
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.listQuery.assessDateStart = item.value[0];
                                this.listQuery.assessDateEnd = item.value[1];
                            } else {
                                this.listQuery.assessDateStart = "";
                                this.listQuery.assessDateEnd = "";
                            }
                        } else if (item.key === "organIds") {
                            this.listQuery[item.key] = item.value ? item.value : [];
                        } else {
                            this.listQuery[item.key] = item.value;
                        }
                        
                    });
                    this.getList(1);
                    break;
                case "reset":
                    this.formData.data.forEach((item) => {
                        if (item.key == 'organIds') {
                            item.value = [];
                            this.listQuery[item.key] = [];
                        } else {
                            item.value = "";
                            this.listQuery[item.key] = "";
                        }
                        
                    });
                    this.setDefaultDate();
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        // 修改年级查询项
        changeSel(data) {
            // 修改统计范围
            if (data.key === "gradeId") {
                const classIndex = this.formData.data.findIndex(
                    (i) => i.key === "classId",
                );
                this.formData.data[classIndex].value = "";
                this.formData.data[classIndex].list = [];
                if (data.value) {
                    this.getClassList(data.value);
                }
            }
        },
        clickSmartReviews() {
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.$emit("changeComp", {
                name: "SmartReviews",
            });
        },
        // 指标管理
        // clickTargetManage() {
        //     //存储筛选项及滚动条高度
        //     let recordsList =  new RecordsList()
        //     console.log(this.$route.name,'this.$route.name');
        //     recordsList.set(this.$route.name, {
        //         formData: this.formData,
        //         listQuery:  this.listQuery,
        //         tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
        //     })
        //     this.$emit("changeComp", {
        //         name: "targetManages",
        //         data: {
        //             type:1
        //         }
        //     });
        // },
        // 通知管理
        clickNoticeManage() {
             //存储筛选项及滚动条高度
             let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            console.log(recordsList.get(this.$route.name),'获取--存储筛选项及滚动条高度');
            this.$emit("changeComp", {
                name: "noticeManage",
            });
        },
        /**
         * @Description: 打开归档弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 19:36
         */
        clickArchivesManage() {
            this.archivesDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 激活档案
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 19:35
         */
        activeArchive(data) {
            this.$confirm("此操作将激活档案, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.activeLoading = true;
                    this._fet("/school/schoolMoralDocument/deleteActivation", {
                        id: data.id,
                    })
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("激活成功");
                                this.getList()
                                this.getMoralDocument();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .finally(() => {
                            setTimeout(() => {
                                this.activeLoading = false;
                            }, 300);
                        });
                })
                .catch(() => {});
        },
        changeSemester(data) {
            let find = this.semesterList.find((i) => i.id === data);
            this.archivesForm.schoolId = this.schoolId;
            this.archivesForm.name = find.name;
            this.archivesForm.startTime = find.startTime + " 00:00:00";
            this.archivesForm.endTime = find.endTime + " 23:59:59";
        },
        /**
         * @Description: 归档
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 19:35
         */
        handleArchivesSave() {
            this.$refs.archivesRef.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    this._fet(
                        "/school/schoolMoralDocument/save",
                        this.archivesForm,
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("归档成功");
                                this.getList()
                                this.getMoralCalendar();
                                this.getMoralDocument();
                                this.handleArchivesClose();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        /**
         * @Description: 关闭归档弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/3/30 19:35
         */
        handleArchivesClose() {
            this.archivesDialogObj.dialogVisible = false;
            this.archivesForm = this.$options.data.call().archivesForm;
            this.$nextTick(() => {
                this.$refs.archivesRef.clearValidate();
            });
        },
        /**
         * @Description: 打开添加考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        clickAddAudit(data) {
            this.isUpdataImg = true
            this.isUpdataVideo = true
            this.dialogObj.dialogVisible = true;
            const keyList = ["studentId", "gradeId", "classId", "onlyCode"];
            keyList.forEach((item) => {
                this.ruleForm[item] = data[item];
            });
            this.ruleForm.assessDate = this.$moment().format("YYYY-MM-DD HH:mm");
			this.$nextTick(()=>{
				this.$refs.formRef.validateField(['assessDate'],(err)=>{
					console.log('err=====',err);
				})
			})

            this.ruleForm.schoolId = this.schoolId;
            // 设置考核行为下拉列表
            this.behaviorList = this.ruleForm.ruleType == 1 ? this.criticismBehaviorListAll : this.praiseBehaviorListAll;
        },
        changeRuleType(e){
            console.log('eeeeeee',e);
            this.ruleForm.ruleName = "";
			this.ruleForm.behaviorName = "";
            this.ruleForm.ruleId = "";
            this.ruleForm.behaviorIds = [];
			this.ruleForm.score = "";
			this.ruleForm.ruleType = e;
            this.behaviorList = this.ruleForm.ruleType == 1 ? this.criticismBehaviorListAll : this.praiseBehaviorListAll;
        },
        /**
         * @Description: 选择考核指标，获取考核行为数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:50
         */
        changeRule(data) {
            console.log('changeRulechangeRulechangeRulechangeRule',data);
            
            this.ruleForm.ruleType = "";
            this.ruleForm.behaviorIds = [];
            this.totalScore = 0;
            const current = treeFind(this.ruleList, (x) => x.id === data);
            if (current) {
                this.ruleForm.ruleType = current.ruleType;
                this.behaviorList = current.behaviorList;
            }
            // 如果清除了考核指标
            if (!data) {
                // 如果没有考核类型，则将考核行为列表重置为全部；如果有考核类型，所以将考核行为列表重置为该考核类型的全部
                if(!this.ruleForm.ruleType){
                    this.behaviorList = this.behaviorListAll
                } else {
                    this.behaviorList = this.ruleForm.ruleType == 1 ? this.criticismBehaviorListAll : this.praiseBehaviorListAll;
                }
            }
        },
        changeBehavior(data) {
            this.totalScore = 0
            console.log('this.ruleForm.behaviorId111111',this.ruleForm.behaviorIds,data);
            if (data.length > 0) {
                let firstItem = data[0]
                let parentId = this.behaviorList.find((i) => i.id === firstItem).parentId;
                this.ruleForm.ruleId = parentId;
                // this.changeRule(this.ruleForm.ruleId)
                this.ruleForm.ruleType = this.behaviorList.find((i) => i.id === firstItem).ruleType
                this.behaviorList = this.behaviorList.filter((item) => item.parentId === parentId);
            }
            let chooseBehaviorList = []
            data.forEach(items=>{
                let find = this.behaviorList.find((i) => i.id === items);
                chooseBehaviorList.push(find)
            })
            if (chooseBehaviorList.length > 0) {
                chooseBehaviorList.forEach(iv=>{
                    this.totalScore += Number(iv.score)
                })
            } else {
                this.totalScore = 0;
            }
        },
        /**
         * handleExceed  当上传的文件超出限制时
         * beforeUpload  上传校验
         */
        //限制传文件个数
        handleExceed(files, fileList) {
            console.log(this.acceptType,'上传的类型')
            if (this.acceptType == "image"){
                this.$message.warning("最多选择 3 张图片");
            } else if (this.acceptType == "video") {
                this.$message.warning("最多选择 1 个视频");
            } else {
                this.$message.warning("最多选择 3 张图片 或 1 个视频");
            }
        },
        deleteSwiperImg(index) {
            this.fileList1.splice(index, 1)
            this.ruleForm.img.splice(index, 1)
            console.log("打印删除后的list",this.ruleForm.img.length, this.acceptType)
            //如果第一个是图片，后面只能选图片
            if (this.ruleForm.img.length > 1 || (this.ruleForm.img.length === 1 &&  this.acceptType === 'image')) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
            } else {
                this.acceptValue = "image/*,video/mp4"
                this.acceptType = ""
            }

            if(this.ruleForm.img.length<3){
                this.isUpdataImg = true
            }
            if (this.ruleForm.img.length < 1){
                this.isUpdataVideo = true
            }
        },
        /**
         * @Description: 上传校验
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        beforeUpload(file) {
            console.log("file上传文件的类型", file,this.ruleForm.img,'222',this.acceptType)
            if(this.ruleForm.img.length >=1 && this.acceptType ==='image'){
                console.log('进来了吗');
                if (file.type === "video/mp4") {
                    this.$message.error("只允许上传图片!");
                    this.acceptType ==='image'
                    return false;
                }
            }
            //如果上传的是图片格式-限制上传3张
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            //如果上传的是视频格式-限制上传1个视频大小在100M以内
            const isVideo = file.type === "video/mp4";
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (isImage) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                if (!is50) {
                    this.$message.error("上传图片名称不能超过50个字符!");
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                    return false;
                }
            } else if (isVideo) {
                this.acceptType = "video"
                this.acceptValue = "video/mp4"
                if (!isLt100M) {
                    this.$message.error("视频超过系统限制请编辑后上传");
                    this.acceptValue = "image/*,video/mp4"
                    return false;
                }
               // 校验上传视频的时长不能超过20S
                return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                let _this = this;
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    let duration = video.duration;
                    //alert('视频时长为：' + duration + '秒');
                    if(duration > 20) {
                        _this.$message.error('视频超过系统限制请编辑后上传');
                        _this.acceptValue = "image/*,video/mp4"
                        reject('视频超过系统限制请编辑后上传');
                    } else {
                        resolve(true);
                    }
                }
                video.onerror = function() {
                    reject('无法获取视频时长');
                }
                video.src = URL.createObjectURL(file);
                });
            } else if(this.ruleForm.img.length >=1) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                this.$message.error("只允许上传图片!");
                return false;
            } else {
                this.acceptType = ""
                this.acceptValue = "image/*,video/mp4"
                this.$message.error("只允许上传图片 或 视频!");
                return false;
            }
           console.log("上传后的类型", this.acceptType)
        },
        /**
         * @Description: 上传功能回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        handleSuccess(res, file, fileList) {
            if (res.code === "200") {
                this.ruleForm.img.push(res.data.url);
                if(this.ruleForm.img.length>=3){
                    this.isUpdataImg = false
                }
                if (this.ruleForm.img.length>= 1 && this.acceptType === "video") {
                    this.isUpdataVideo = false
                }
            }
        },
        /**
         * @Description: 添加考核行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        handleSave() {
            if (!this.ruleForm.gradeId || !this.ruleForm.classId) {
                this.$message.warning('该学生缺少年级班级信息，请补充后再添加考核')
                return
            }
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    let form = JSON.parse(JSON.stringify(this.ruleForm))
                    form.img = form.img.toString()
                    form.score = this.totalScore
                    console.log('form111',form);
                    this._fet(
                        "/school/schoolMoralStudentDetail/save",
                        form,
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("添加考核成功");
                                this.handleClose();
                                this.setDefaultDate()
                                this.getList();
                                this.fileList1 = [];
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        /**
         * @Description: 关闭添加考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        handleClose() {
            this.isUpdataImg = true
            this.isUpdataVideo = true
            this.acceptValue = "image/*,video/mp4"
            this.dialogObj.dialogVisible = false;
            this.ruleForm = this.$options.data.call().ruleForm;
            this.behaviorList = []
            this.fileList1 = [];
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
        },
        // 导入
        clickImport() {
            this.importDialog.dialogVisible = true;
        },
        async importDialogSubmit() {
            if (this.fileList.length === 0) {
                return this.$message.warning("请上传文件");
            }
            let formData = new FormData();
            formData.append("file", this.fileList[0].raw);
            formData.append("schoolId", this.schoolId);
            const checkTeacherModel = new CheckTeacherModel();
            this.btnLoading = true;
            checkTeacherModel
                .uploadMoralStudent(formData)
                .then((res) => {
                    this.handleUploadSuccess(res.data);
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        },
        handleUploadSuccess(res) {
            if (res.code === "200") {
                this.$message.success("导入成功");
                this.importDialogClose();
                this.getList();
            } else if (res.code === "20013") {
                this.downloadErrorList(res);
            } else {
                this.$message.error(res.msg);
            }
        },
        downloadErrorList(data) {
            this.$confirm(data.msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    downloadFile(
                        {
                            url: "/school/schoolMoralStudentDetail/errorExport",
                            method: "post",
                            form: {
                                paramData: data.info.paramData,
                            },
                        },
                        () => {},
                        () => {},
                    );
                })
                .catch(() => {});
        },
        importDialogClose() {
            this.fileList = [];
            this.importDialog.dialogVisible = false;
        },
        handleChange(file) {
            let flag = this.beforeFileUpload(file);
            if (flag !== false) {
                this.fileList = [file];
            } else {
                this.fileList = [];
            }
        },
        beforeFileUpload(file) {
            const fileType = file.name.substring(file.name.lastIndexOf("."));
            const isImg = [".xlsx", ".xls"].includes(fileType);
            const is30 = file.name.length < 30;
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message.error(`上传文件格式仅支持xlsx和xls!`);
                return false;
            }
            if (!is30) {
                this.$message.error("上传文件名称不能超过30个字符!");
                return false;
            }
            if (!isLt10M) {
                this.$message.error(`上传文件的大小不能超过10M!`);
                return false;
            }
        },
        downloadTemplate() {
            downloadFile(
                {
                    url: '/school/schoolMoralStudentDetail/template',
                    method: "get",
                },
                () => {},
                () => {},
            );
            // this._get("/school/schoolMoralStudentDetail/template").then(
            //     (res) => {
            //         console.log(res);
                    
            //         if (res.data.code === "200") {
                        
            //             let blob = new Blob([res.data])
            //             let url = window.URL.createObjectURL(blob)
            // let aLink = document.createElement('a')
            // aLink.style.display = 'none'
            // aLink.href = url
            // aLink.setAttribute('download', fileName)
            // document.body.appendChild(aLink)
            // aLink.click()
            // document.body.removeChild(aLink) // 下载完成移除元素
            // window.URL.revokeObjectURL(url) // 释放掉blob对象
            //             // downloadFile(
            //             //     {
            //             //         url: res.data.data,
            //             //         method: "get",
            //             //     },
            //             //     () => {},
            //             //     () => {},
            //             // );
            //         }
            //     },
            // );
        },
        // 导出
        clickExport() {
            this.exportLoading = true;
            let obj = {
                schoolId: this.schoolId,
            };
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    if (item.value && item.value.length > 0) {
                        obj.assessDateStart = item.value[0];
                        obj.assessDateEnd = item.value[1];
                    } else {
                        obj.assessDateStart = "";
                        obj.assessDateEnd = "";
                    }
                } else if (item.key === "organIds") {
                        obj[item.key] = item.value ? item.value : [];
                } else {
                    obj[item.key] = item.value;
                }
                
            });
            downloadFile(
                {
                    url: "/school/schoolMoralStudentDetail/export",
                    method: "post",
                    form: obj,
                },
                () => {
                    // this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },
        // 档案详情
        clickStudentName(data) {
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            this.setDefaultDate()
            console.log(recordsList.get(this.$route.name),'获取--存储筛选项及滚动条高度');
            let sex = {
                1: "男",
                2: "女",
            };
            this.$emit("changeComp", {
                name: "ArchivesManage",
                data: {
                    ...data,
                    schoolCalendar: this.schoolCalendar,
                    sexName: sex[+data.sex],
                    assessDateStart: this.listQuery.assessDateStart,
                    assessDateEnd: this.listQuery.assessDateEnd,
                },
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        getOrg() {
            let obj = {...this.listQuery};
            Object.assign(obj, {jobScope: ["1", "2", "3"],needPermissionConfig: '1'});
            this._fet(
                // "/school/schoolOrgan/listByCondition",
                "/school/schoolOrgan/getGradeAndClassListByMoralManage",
                obj,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.organList = res.data.data;
                    const treeData = listToTree(res.data.data, {
                        parentKey: "parentOrg",
                    });
                    this.treeData.dataList = treeData.filter(
                        (i) => i.organType !== "1",
                    );
                    console.log(
                        this.treeData.dataList,
                        "this.treeData.dataList",
                    );
                    let jsonList = JSON.stringify(this.treeData.dataList);
                    let jsonData = jsonList.replace(
                        /\"children\":\[]/g,
                        '"children":null',
                    );
                    this.formData.data.forEach((item) => {
                        let keyAboutClassArr = ["organIds"]
                        if (keyAboutClassArr.includes(item.key)) {
                            item.list = JSON.parse(jsonData);
                        }
                    });

                    // this.list = this.treeData.dataList
                } else {
                    this.$message.error("数据请求失败，请重试");
                }
            });
        },
        /**
         * @Description: 获取所有考核行为
         * @Author: 张冰华
         * @Date: 2024-11-21 09:25:50
         */        
        async getBehaviorListAll() {
            let param = {
                schoolId: this.$store.state.schoolId,
                category: '2',//宿舍：1、 德育：2
            }
            await this._fet("/school/schoolMoralRule/queryAllBehavior",param,).then(res => {
                if (res.data.code === "200") {
                    this.behaviorListAll = res.data.data
                } else {}
            })
        },
        behaviorDisabled(data) {
            if (this.ruleForm.behaviorIds.length <= 0) {
                return false
            } else {
                let firstId = this.ruleForm.behaviorIds[0];
                let parentId = this.behaviorList.find(item => item.id === firstId).parentId;
                return data.parentId !== parentId
                
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    width: 100%;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 0px 4px 4px 4px;
    .expand-filter {
        padding: 0;
        flex: 1;
        .date-picker-wrap {
            display: flex;
            align-items: center;
            .date-icon {
                margin-left: 10px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 12px;
                height: 12px;
                background: #d4d6d9;
                border-radius: 50%;
                color: #ffffff;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }
}

::v-deep .el-button.is-disabled.el-button--text {
    color: #363b40;
}

.simple-dialog-form {
    .footer-button-group {
        text-align: right;
    }

    .behavior-select {
        ::v-deep .el-select-dropdown__item{
            width: 452px;
        }
    }
}

.archives-wrap {
    .text1 {
        line-height: 12px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2b2f33;
        margin-bottom: 20px;
    }

    .archives-list-wrap {
        box-sizing: border-box;
        width: 544px;
        height: 220px;
        overflow: auto;
        padding: 12px 16px 24px 10px;
        background: #ffffff;
        border: 1px solid #edf0f2;
        border-radius: 4px;
        margin: 28px 0;

        .text2 {
            line-height: 12px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808387;
        }

        .archives-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 25px;

            .archives-name {
                line-height: 12px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2f33;

                .el-icon-circle-check {
                    color: #04ccab;
                    margin-right: 5px;
                }
            }
        }
    }
}
.swiper-wrap {
    display: flex;
    flex-wrap: wrap;
}

.img_each {
    width: 80px;
    height: 80px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    border: 1px solid #c0ccda;
    margin-right: 10px;

    img {
        object-fit: cover;
    }

    .del {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        .el-icon-delete {
            font-size: 20px;
            color: #ffffff;
        }
    }
}
.upload-wrap {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
// 图片数量等于限制数量时隐藏上传组件
::v-deep.hide {
    .el-upload--avatar-uploader {
        display: none !important;
    }
}
.upload-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;

    .add-icon {
        margin-top: 24px;
        font-size: 20px;
        line-height: 20px;
        color: #c2c8cc;
    }

    .upload-text {
        margin-top: 15px;
        line-height: 12px;
        font-size: 12px;
        color: #999999;
    }
}


.empty-text {
    text-align: center;
    margin-top: 70px;
}
.tipText{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #BBBDBF;
}
.el-dropdown-menu__item {
    &:hover {
        background-color: #fff;
    }
}
</style>
